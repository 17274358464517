<template>
	<!-- 监理周报 -->
  <div class="mod-config">
	<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
		<el-card style="margin-top: 20px; height: 100%;">
			<div class="elCardTitle">基本信息</div>
			<el-row>
				<el-col :span="8">
					<el-form-item label="工作控制号">
						{{dataForm.workNo}}
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="项目名称">
						<el-input class="selItemInput" v-model="dataForm.projectInfo.projectName" placeholder="请输入"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="报告编号">
						{{dataForm.weeklyName}}
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="委托单位">
						<el-input class="selItemInput" v-model="dataForm.projectInfo.projectEntrust" placeholder="请输入"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="承包单位">
						<el-input class="selItemInput" v-model="dataForm.projectInfo.contractCompany" placeholder="请输入"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="日  期">
						{{dataForm.beginTime}}至{{dataForm.endTime}}
					</el-form-item>
				</el-col>
			</el-row>
		</el-card>
		<el-card style="margin-top: 20px; height: 100%;">
			<div slot="header" class="clearfix">
				<span>一、本周监理工作概述</span>
			</div>
			<el-row>
				<el-col :span="24">
					<div class="elCardTitle">1、基本概况</div>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="1.1 本周生产进度" label-width="auto">
						<el-radio v-model="dataForm.productionSituation" label="0">正常</el-radio>
						<el-radio v-model="dataForm.productionSituation" label="1">缓慢</el-radio>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="        总体进度" label-width="auto">
						<el-radio v-model="dataForm.populationSituation" label="0">正常</el-radio>
						<el-radio v-model="dataForm.populationSituation" label="1">缓慢</el-radio>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="1.2 本周工程质量状况" label-width="auto">
						<el-radio v-model="dataForm.qualitySituation" label="0">正常</el-radio>
						  <el-radio v-model="dataForm.qualitySituation" label="1">不满意</el-radio>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="1.3 本周发出监理工作联系单共" label-width="auto">
						<el-input class="selItemInput" v-model="dataForm.contactNum" placeholder="请输入"></el-input> 份
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="1.4 本周发出监理通知单共" label-width="auto">
						<el-input class="selItemInput" v-model="dataForm.noticeNum" placeholder="请输入"></el-input> 份
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="1.5 本周文件审查 共" label-width="auto">
						<el-input class="selItemInput" v-model="dataForm.fileCheckNum" placeholder="请输入"></el-input> 份
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="1.6 本周检验项目" label-width="auto">
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-table
							class="dataListTable"
							:data="dataForm.testItemRecords"
							header-align="center"
							style="width: 100%"
							header-cell-class-name="dataListTHeader">
						<el-table-column
								prop="subjectNo"
								label=" "
								width="120"
								align="center">
						</el-table-column>
						<el-table-column
								prop="majorName"
								label="专业"
								width="120"
								align="center">
						</el-table-column>
						<el-table-column
								prop="testConclusionCount"
								label="接受"
								align="center">
						</el-table-column>
						<el-table-column
								prop="testConclusionWhereCount"
								label="有条件接受 "
								align="center">
						</el-table-column>
						<el-table-column
								prop="recheckAfterCorrectionCount"
								label="修正后复检"
								align="center">
						</el-table-column>
						<el-table-column
								prop="testConclusionRefuseCount"
								label="不接受"
								align="center">
						</el-table-column>
						<el-table-column
								prop="testConclusionTotalCount"
								label="总计 "
								align="center">
						</el-table-column>
					</el-table>
				</el-col>
				<el-col :span="24">
					<el-form-item label="1.7 巡检整改项目" label-width="auto">
					</el-form-item>
				</el-col>
				<el-col :span="24">
						<el-table
								class="dataListTable"
								:data="dataForm.patrolItemRecords"
								header-align="center"
								style="width: 100%;"
								header-cell-class-name="dataListTHeader">
							<el-table-column
									prop="subjectNo"
									label=" "
									width="120"
									align="center">
							</el-table-column>
							<el-table-column
									prop="majorName"
									label="专业"
									width="120"
									align="center">
							</el-table-column>
							<el-table-column
									prop="applyNum"
									label="本周提出整改数"
									align="center">
							</el-table-column>
							<el-table-column
									prop="alreadyModifyNum"
									label="本周已整改数"
									align="center">
							</el-table-column>
							<el-table-column
									prop="notFinishNum"
									label="截止本周未整改数 "
									align="center">
							</el-table-column>
						</el-table>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24" style="margin-top: 20px;">
					<div class="elCardTitle">2、情况综述</div>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-input type="textarea" rows="5" v-model="dataForm.overviewDesc" placeholder="请填写"></el-input>
				</el-col>
			</el-row>
		</el-card>
		<el-card style="margin-top: 20px; height: 100%;">
			<div slot="header" class="clearfix">
				<span>二、进度情况</span>
			</div>
			<el-row>
				<el-col :span="24">
					<div class="elCardTitle">1、项目总计划进度与实际进度对照</div>
				</el-col>
				<el-col :span="24">
					<el-table
							class="dataListTable"
							:data="dataForm.nodeList"
							header-align="center"
							style="width: 100%;"
							header-cell-class-name="dataListTHeader">
						<el-table-column
								prop="nodeName"
								label="节点"
								align="center">
						</el-table-column>
						<el-table-column
								prop="planTime"
								label="计划时间"
								align="center">
						</el-table-column>
						<el-table-column
								prop="factTime"
								label="实际时间"
								align="center">
						</el-table-column>
						<el-table-column
								prop="nodeStatusName"
								label="状态"
								align="center">
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24" style="margin-top: 20px;">
					<div class="elCardTitle">2、进度分析</div>
				</el-col>
				<el-col :span="24">
					<el-input type="textarea" rows="5" v-model="dataForm.progressAnalysisDesc" placeholder="请填写"></el-input>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24" style="margin-top: 20px;">
					<div class="elCardTitle">3、设计进度情况</div>
				</el-col>
				<el-col :span="24">
					<el-input type="textarea" rows="5" v-model="dataForm.designProgressDesc" placeholder="请填写"></el-input>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24" style="margin-top: 20px;">
					<div class="elCardTitle">4、生产及技术准备情况</div>
				</el-col>
				<el-col :span="24">
					<el-input type="textarea" rows="5" v-model="dataForm.productionAndTechnologyDesc" placeholder="请填写"></el-input>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24" style="margin-top: 20px;">
					<div class="elCardTitle">5、主要外购件进度情况</div>
				</el-col>
				<el-col :span="24">
					<el-table
							class="dataListTable"
							:data="dataForm.purchaseDeviceList"
							header-align="center"
							style="width: 100%;"
							header-cell-class-name="dataListTHeader">
						<el-table-column
								prop="deviceName"
								label="设备名称"
								align="left"
						width="200" show-overflow-tooltip>
						</el-table-column>
						<el-table-column
								prop="technicalFactory"
								label="技术协议要求厂家"
								align="left"
								width="200" show-overflow-tooltip>
						</el-table-column>
						<el-table-column
								prop="quantity"
								label="数量"
								align="center">
						</el-table-column>
						<el-table-column
								prop="contractSignFlgName"
								label="合同签订情况"
								width="150" show-overflow-tooltip
								align="center">
						</el-table-column>
						<el-table-column
								prop="deviceFactory"
								label="实际订货厂家"
								width="230" show-overflow-tooltip
								align="left">
						</el-table-column>
						<el-table-column
								prop="arrivalDatePlan"
								label="计划到货"
								align="left">
						</el-table-column>
						<el-table-column
								prop="arrivalDateFact"
								label="实际到货"
								width="250" show-overflow-tooltip
								align="left">
						</el-table-column>
						<el-table-column
								prop="remark"
								label="备 注"
								width="300" show-overflow-tooltip
								align="left">
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24" style="margin-top: 20px;">
					<div class="elCardTitle">6、现场进度情况（已全部完工的基地或阶段不再进行跟踪）</div>
				</el-col>
			</el-row>
			<el-row>
				<div v-for="(item,i) in dataForm.progressPartsList">
					<el-col :span="24" style="margin-top: 10px;">
						<div class="elCardTitle">6.{{i + 1}} {{item.sceneTypeName}}</div>
					</el-col>
					<el-col :span="24">
						<el-table
								class="dataListTable"
								:data="item.subList"
								header-align="center"
								style="width: 100%;"
								header-cell-class-name="dataListTHeader">
							<el-table-column
									prop="partsName"
									label="项目名称"
									align="center">
							</el-table-column>
							<el-table-column
									prop="projectNo"
									label="机 号"
									align="center">
							</el-table-column>
							<el-table-column
									prop="progressDescribe"
									label="进度情况"
									width="350"
									show-overflow-tooltip
									align="center">
							</el-table-column>
							<el-table-column
									prop="proPerName"
									label="百分比文字"
									align="center">
							</el-table-column>
							<el-table-column
									prop="remark"
									label="备注"
									width="350"
									show-overflow-tooltip
									align="center">
<!--								<template  slot-scope="scope">-->
<!--									<el-input class="selItemInput descAreaLg2" type="textarea" rows="3" v-model="scope.row.remark" placeholder="请填写"></el-input>-->
<!--								</template>-->
							</el-table-column>
						</el-table>
					</el-col>
				</div>
			</el-row>
			<el-col :span="24" style="margin-top: 10px;">
				<el-form-item label="综述" label-width="160px">
					<el-input class="selItemInput descAreaLg" type="textarea" rows="3" v-model="dataForm.siteOverviewDesc" placeholder="请填写"></el-input>
				</el-form-item>
			</el-col>
		</el-card>
		<el-card style="margin-top: 20px; height: 100%;">
			<div slot="header" class="clearfix">
				<span>三、一周质量情况</span>
			</div>
			<el-row>
				<el-col :span="24">
					<div class="elCardTitle">1、停止点报验情况</div>
				</el-col>
			</el-row>
			<el-row>
				<div v-for="(item,i) in dataForm.testRecordList">
					<el-col :span="24" style="margin-top: 10px;">
						<div class="elCardTitle">1.{{i+1}} {{item.majorName}}</div>
					</el-col>
					<el-col :span="24">
						<el-table
								class="dataListTable"
								:data="item.subList"
								header-align="center"
								style="width: 100%;"
								header-cell-class-name="dataListTHeader">
							<el-table-column
									type="index"
									label="序号"
									align="center" width="80">
							</el-table-column>
							<el-table-column
									prop="partsName"
									label="部件"
									align="center">
							</el-table-column>
							<el-table-column
									prop="insProContents"
									label="报验工序和内容"
									align="center">
							</el-table-column>
							<el-table-column
									prop="testConclusion"
									label="检验结论"
									align="center">
								<template  slot-scope="scope">
									<span v-if="scope.row.testConclusion ==='0'">接受</span>
									<span v-if="scope.row.testConclusion ==='1'">不接受</span>
									<span v-if="scope.row.testConclusion ==='2'">有条件接受</span>
									<span v-if="scope.row.testConclusion ==='3'">修正后复检</span>
								</template>
							</el-table-column>
							<el-table-column
									prop="probContext"
									label="检验内容"
									align="center"
									width="350" show-overflow-tooltip>
							</el-table-column>
							<el-table-column
									label="备注"
									prop="remark"
									align="center"
							width="350" show-overflow-tooltip>
<!--								<template  slot-scope="scope">-->
<!--									<el-input class="selItemInput descAreaLg2" type="textarea" rows="3" v-model="scope.row.remark" placeholder="请填写"></el-input>-->
<!--								</template>-->
							</el-table-column>
						</el-table>
					</el-col>
				</div>
			</el-row>
			<el-row>
				<el-col :span="24" style="margin-top: 20px;">
					<div class="elCardTitle">2、旁站情况</div>
				</el-col>
				<el-col :span="24">
					<el-table
							class="dataListTable"
							:data="dataForm.onsiteList"
							header-align="center"
							style="width: 100%;"
							header-cell-class-name="dataListTHeader">
						<el-table-column
								type="index"
								label="序号"
								align="center" width="80">
						</el-table-column>
						<el-table-column
								prop="projectNo"
								label="机号"
								align="center">
						</el-table-column>
						<el-table-column
								prop="onsiteItem"
								label="项目"
								align="center">
						</el-table-column>
						<el-table-column
								prop="onsiteContent"
								label="内容"
								align="center">
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24" style="margin-top: 20px;">
					<div class="elCardTitle">3、巡检情况</div>
				</el-col>
				<el-col :span="24">
					<el-input type="textarea" rows="5" v-model="dataForm.inspectionDesc" placeholder="请填写"></el-input>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24" style="margin-top: 20px;">
					<div class="elCardTitle">4、监理联系单、监理通知单处理及跟踪情况</div>
				</el-col>
				<el-col :span="24">
					<el-form-item label="截止本周末共下发" label-width="260px">
						<el-input class="selItemInput" v-model="dataForm.contactTotalNum" placeholder="请输入"></el-input> 份监理通知单，已整改完成
						<el-input class="selItemInput" v-model="dataForm.contactFinishNum" placeholder="请输入"></el-input> 份
					</el-form-item>
				</el-col>
				<el-col :span="24" style="margin-bottom: 10px;">
					<el-table
							class="dataListTable"
							:data="dataForm.contactItemRecords"
							header-align="center"
							style="width: 100%;"
							header-cell-class-name="dataListTHeader">
						<el-table-column
								type="index"
								label="序号"
								align="center" width="60">
						</el-table-column>
						<el-table-column
								prop="primaryContent"
								label="主要内容" width="400"
								align="center" show-overflow-tooltip>
						</el-table-column>
						<el-table-column
								prop="sendTime"
								label="签发时间"
								align="center">
						</el-table-column>
						<el-table-column
								prop="receiverName"
								label="接收人"
								align="center">
						</el-table-column>
						<el-table-column
								prop="handleOptions"
								label="处理情况" width="300"
								align="center" show-overflow-tooltip>
						</el-table-column>
						<el-table-column
								label="备 注"
								prop="remark" width="300"
								align="center" show-overflow-tooltip>
						</el-table-column>
					</el-table>
				</el-col>
				<el-col :span="24">
					<el-form-item label="截止本周末共下发" label-width="260px">
						<el-input class="selItemInput" v-model="dataForm.noticeTotalNum" placeholder="请输入"></el-input> 份监理联系单，已整改完成
						<el-input class="selItemInput" v-model="dataForm.noticeFinishNum" placeholder="请输入"></el-input> 份
					</el-form-item>
				</el-col>
				<el-col :span="24" style="margin-bottom: 10px;">
					<el-table
							class="dataListTable"
							:data="dataForm.noticeItemRecords"
							header-align="center"
							style="width: 100%;"
							header-cell-class-name="dataListTHeader">
						<el-table-column
								type="index"
								label="序号"
								align="center" width="60">
						</el-table-column>
						<el-table-column
								prop="primaryContent"
								label="主要内容"
								align="center" width="400" show-overflow-tooltip>
						</el-table-column>
						<el-table-column
								prop="sendTime"
								label="签发时间"
								align="center">
						</el-table-column>
						<el-table-column
								prop="receiverName"
								label="接收人"
								align="center" >
						</el-table-column>
						<el-table-column
								prop="handleOptions"
								label="处理情况" width="300"
								align="center" show-overflow-tooltip>
						</el-table-column>
						<el-table-column
								label="备 注"
								prop="remark" width="300"
								align="center" show-overflow-tooltip>
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24" style="margin-top: 20px;">
					<div class="elCardTitle">5、签收及审查文件情况</div>
				</el-col>
				<el-col :span="24">
					<el-table
							class="dataListTable"
							:data="dataForm.fileCheckRecords"
							header-align="center"
							style="width: 100%;"
							header-cell-class-name="dataListTHeader">
						<el-table-column
								type="index"
								label="序号"
								align="center" width="80">
						</el-table-column>
						<el-table-column
								prop="fileName"
								label="文件名称"
								width="240" show-overflow-tooltip
								align="center">
						</el-table-column>
						<el-table-column
								prop="examineStatusName"
								label="审查状态"
								align="center" width="120" show-overflow-tooltip>
						</el-table-column>
						<el-table-column
								prop="examineSuggestion"
								label="审查意见"
								align="left" show-overflow-tooltip>
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24" style="margin-top: 20px;">
					<div class="elCardTitle">6、会议与会谈情况</div>
				</el-col>
				<el-col :span="24">
					<el-input type="textarea" rows="5" v-model="dataForm.meetingDesc" placeholder="请填写"></el-input>
				</el-col>
			</el-row>
		</el-card>
		<el-card style="margin-top: 20px; height: 100%;">
			<div slot="header" class="clearfix">
				<span>四、安全生产/HSE情况</span>
			</div>
			<el-row>
				<el-col :span="24">
					<el-input type="textarea" rows="5" v-model="dataForm.hseInfoDesc" placeholder="请填写"></el-input>
				</el-col>
			</el-row>
		</el-card>
		<el-card style="margin-top: 20px; height: 100%;">
			<div slot="header" class="clearfix">
				<span>五、监理建议或意见</span>
			</div>
			<el-row>
				<el-col :span="24">
					<el-input type="textarea" rows="5" v-model="dataForm.proposalDesc" placeholder="请填写"></el-input>
				</el-col>
			</el-row>
		</el-card>
        <el-card style="margin-top: 20px; height: 100%;">
            <div slot="header" class="clearfix">
                <span>六、随周报发送的附件</span>
            </div>
            <el-row>
                <el-col :span="24">
                    <el-table
                            class="dataListTable"
                            :data="dataForm.enclosureList"
                            header-align="center"
                            style="width: 100%;"
                            empty-text="保存后请通过附件上传功能上传附件"
                            header-cell-class-name="dataListTHeader">
                        <el-table-column
                                type="index"
                                label="序号"
                                align="center" width="60">
                        </el-table-column>
                        <el-table-column
                                prop="fileName"
                                label="文件名称"
                                align="center" width="200">
                        </el-table-column>
                        <el-table-column
                                prop="remark"
                                label="备注"
                                align="center">
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
        </el-card>
		<el-card style="margin-top: 20px; height: 100%;">
			<div slot="header" class="clearfix">
				<span>七、现场照片</span>
				<el-button v-preventReClick style="float: right; padding: 3px 0" type="text" @click="addPhoto(2)">新增</el-button>
			</div>
			<el-row>
				<el-col :span="8" v-for="(item,index) in dataForm.qualityPhotoRecords" >
					<el-card :body-style="{ padding: '0px' }" style="margin-bottom: 10px;margin-right: 5px;">
						<el-image :src="item.photoFilePathUrl" :preview-src-list="item.photoViewList" fit="scale-down" class="image"></el-image>
						<div style="padding: 14px;">
							<div style="height: 76px">
								<span>{{item.photoRemark}}</span>
							</div>
							<div class="bottom clearfix">
								<el-button v-preventReClick type="text" class="button" @click="delQualityImg(index)">删除</el-button>
							</div>
						</div>
					</el-card>
				</el-col>.
				<div v-if="!dataForm.qualityPhotoRecords || dataForm.qualityPhotoRecords.length===0">
					<span style="color: #e7b243;font-size: 14px;">暂无质量照片</span>
				</div>
			</el-row>
		</el-card>
		<el-card style="margin-top: 20px; height: 100%; padding-top: 30px;">
			<el-row>
				<el-col :span="8">
					<el-form-item label="报告人">
						<el-input class="selItemInput" v-model="dataForm.reportMan" placeholder="请输入"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="日期">
						<el-date-picker  class="selItemInput"
						  v-model="dataForm.reportTime"
						  type="date"
						  value-format="yyyy-MM-dd"
						  placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="8">
					<el-form-item label="项目总监">
						<el-input class="selItemInput" v-model="dataForm.projectDirector" placeholder="请输入"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item label="日期">
						<el-date-picker  class="selItemInput"
						  v-model="dataForm.projectDirectorTime"
						  type="date"
						  value-format="yyyy-MM-dd"
						  placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
		</el-card>
	</el-form>
	  <el-row class="formBtnGroup" style="margin-top: 40px;">
		<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="goBack()">取消</el-button>
		<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
	  </el-row>

	  <el-dialog title="添加照片" :visible.sync="dialogVisible" width="30%">
		  <el-form>
		  <el-row>
			  <el-col :span="24">
				  <el-form-item label="描述">
					  <el-input type="textarea" rows="5" v-model="photoContent" placeholder="请填写"></el-input>
				  </el-form-item>
			  </el-col>
			  <el-col :span="24">
				  <el-form-item label="照片">
					  <div class="addImgBox" v-for="(item,index) in photoList">
						  <el-image
								  style="width: 100%; height: 100%"
								  :src="item.photoPathUri"
								  fit="cover"></el-image>
						  <div class="delBox">
							  <i class="el-icon-delete" @click="delPhotoImg(index)"></i>
						  </div>
					  </div>
					  <el-upload
							  class="addImgBox"
							  ref="uploadTempImg"
							  :action="action"
							  :http-request="uploadTempImg"
							  accept="image/jpeg,image/png,image/jpg" v-if="!photoList || photoList.length===0">
						  <el-image
								  style="width: 100%; height: 100%"
								  :src="addImg"
								  fit="cover">
						  </el-image>
					  </el-upload>
				  </el-form-item>
			  </el-col>
		  </el-row>
		  </el-form>
		  <span slot="footer" class="dialog-footer">
			<el-button v-preventReClick @click="dialogVisible = false">取 消</el-button>
			<el-button v-preventReClick type="primary" @click="savePhotoList">确 定</el-button>
		</span>

	  </el-dialog>

  </div>
</template>
<script>
	import addImg from "@/assets/images/addImg.png"
	import $common from "@/utils/common.js";
export default {
  data() {
    return {
    	photoFlag : 0,
		dialogVisible:false,
		addImg: addImg,
		recNo: '',
		beginTime:'',
		action:'',
		endTime:'',
		photoList:[],
		photoContent:'',
		workNo: $common.getItem("workNo"),
		// subjectNo: $common.getItem("subjectNo"),
	  dataList:[
		  {
			  name: "1"
		  },
		  {
			  name: "2"
		  },
		  {
			  name: "3"
		  }
	  ],
		dataRule:{},
	  dataForm:{
		  projectInfo:{
			  projectName:''
		  }
	  },
    };
  },
  components: {

  },
	mounted() {
		this.recNo = this.$route.query.recNo;
		this.getWeekInfo();
	},
  methods: {
	  delPhotoImg(index){
		  this.photoList.splice(index,1);
	  },
	  addPhoto(flag){
	  		this.photoList=[];
		  this.photoContent = '';
		  this.dialogVisible = true;
		  this.photoFlag = flag;
	  },
	  uploadTempImg(param){
		  const formData = new FormData();
		  formData.append('file', param.file);
		  this.$refs.uploadTempImg.clearFiles();
		  this.$http.post(
				  this.$store.state.httpUrl + "/file/upload/"+$common.getItem("workNo")+"/95/0",
				  formData,
				  {
					  headers: { 'Content-Type': 'multipart/form-data' }
				  },
		  ).then(res => {
			  res = res.data
			  if (res.resultCode === 200){
				  this.photoList.push({
					  photoPath: res.body.fileSavePath,
					  photoPathUri: res.body.fileUri
				  });
			  } else {
				  this.$message.info('文件上传失败')
			  }
		  })
	  },
	  savePhotoList(){
	  	if (!this.photoContent){
			this.$message.error('请填写照片描述');
			return;
		}
	  	if (!this.photoList || this.photoList.length === 0 ){
			this.$message.error('请选择上传照片');
			return;
		}

	  	if (this.photoFlag  === 1){
	  		let photoViewList = [];
			photoViewList.push(this.photoList[0].photoPathUri);
			let item = {
				photoRemark: this.photoContent,
				photoPath: this.photoList[0].photoPath,
				photoPathIrl: this.photoList[0].photoPathUri,
				photoViewList: photoViewList
			};
			this.dataForm.progressPhotoRecords.push(item);
		} else {
			let photoViewList = [];
			photoViewList.push(this.photoList[0].photoPathUri);
			let item = {
				photoRemark: this.photoContent,
				photoFilePath: this.photoList[0].photoPath,
				photoFilePathUrl: this.photoList[0].photoPathUri,
				photoViewList: photoViewList
			};
			this.dataForm.qualityPhotoRecords.push(item);
		}
	  	this.dialogVisible = false;
	  },
	  addNoticeItem(){
		  let item = {
			  noticeNo:'',
			  primaryContent:'',
			  sendTime:'',
			  closeTime:'',
			  remark:''
		  };
		  this.dataForm.noticeItemRecords.push(item);
	  },
	  deleteNoticeItem(index){
		  this.dataForm.noticeItemRecords.splice(index,1);
	  },
	  addSectionalProgress(){
	  	let item = {
			projectNo:'',
			totalNum:0,
			upNum:0,
			checkNum:0,
			paintingNum:0,
			upShipNum:0
		};
		this.dataForm.sectionalItemRecords.push(item);
	  },
	  deleteSectionalItem(index) {
		  this.dataForm.sectionalItemRecords.splice(index,1);
	  },
	  addMooringItem(){
		  let item = {
			  projectNo:'',
			  testItemNum:0,
			  testFinishItemNum:0,
			  shipFinishItemNum:0,
			  turbineFinishItemNum:0,
			  electricalFinishItemNum:0
		  };
		  this.dataForm.mooringTestItemRecords.push(item);
	  },
	  deleteMooringItem(index){
		  this.dataForm.mooringTestItemRecords.splice(index,1);
	  },
	 goBack(){
		 this.$router.back()
	 },
	  getWeekInfo() {
		  this.$http({
			  url:  this.$store.state.httpUrl + "/business/weeklyreportinfo/createWeekData",
			  method: "get",
			  params: {
				  workNo: this.workNo,
				  // subjectNo: this.subjectNo,
				  // beginTime: this.beginTime,
				  // endTime: this.endTime
				  recNo: this.recNo
			  },
		  }).then(({ data }) => {
			  if (data && data.resultCode === 200) {
				  this.dataForm = data.body;
				  // this.dataForm.beginTime= this.beginTime;
				  // this.dataForm.endTime= this.endTime;
				  // if (!this.dataForm.reportTime){
					//   this.dataForm.reportTime = this.getCurrentTime()
				  // }
				  // if (!this.dataForm.projectDirectorTime){
					//   this.dataForm.projectDirectorTime = this.getCurrentTime()
				  // }
			  }
		  });
	  },
	  uploadImg(param){
		  const formData = new FormData();
		  formData.append('file', param.file);
		  this.$refs.uploadImg.clearFiles();
		  this.$http.post(
				  this.$store.state.httpUrl + "/file/upload/"+$common.getItem("workNo")+"/95/0",
				  formData,
				  {
					  headers: { 'Content-Type': 'multipart/form-data' }
				  },
		  ).then(res => {
			  res = res.data
			  if (res.resultCode == 200){
				  this.dataForm.progressPhotoRecords.push({
					  photoPath: res.body.fileSavePath,
					  photoPathIrl: res.body.fileUri
				  });
			  } else {
				  this.$message.info('文件上传失败')
			  }
		  })
	  },
	  delImg(index){
		  this.dataForm.progressPhotoRecords.splice(index,1);
	  },
	  delQualityImg(index){
		  this.dataForm.qualityPhotoRecords.splice(index,1);
	  },
	  getCurrentTime() {
		  //获取当前时间并打印
		  var _this = this;
		  let yy = new Date().getFullYear();
		  let mm = new Date().getMonth()+1;
		  let dd = new Date().getDate();
		  let hh = new Date().getHours();
		  let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
		  let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
		  return yy+'-'+mm+'-'+dd;
	  },
	  dataFormSubmit(){
		  // let _url = "/business/fileinfo/downloadWeek";
		  let _url = "/business/weeklyreportinfo/saveWeekData";
		  this.$http({
			  url: this.$store.state.httpUrl + _url,
			  method: "post",
			  data: this.$http.adornData(this.dataForm)
		  }).then(({ data }) => {
			  if (data && data.resultCode === 200) {
				  this.$message({
					  message: "操作成功",
					  type: "success",
					  duration: 1500,
					  onClose: () => {
						  this.$router.push({name:'weekly'});
					  }
				  });
			  } else {
				  this.$message.error(data.msg);
			  }
		  });
	  }
  }
};
</script>
<style lang="scss" scoped="scoped">
	.el-form-item{
		margin-bottom: 5px !important;
	}
	.addImgBox{
		width: 136px;
		height: 136px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
	}
	.addImgBox200{
		width: 200px;
		height: 200px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
	}
	.elCardTitle{
		font-size: 18px;
		font-weight: bold;
		color: #00428e;
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid #00428e;
	}
	.selItemInput.descAreaLg{
		width: 1030px !important;
	}
	.selItemInput.descAreaLg1{
		width: 430px !important;
	}
	.selItemInput.descAreaLg2{
		width: 200px !important;
	}
	.time {
		font-size: 13px;
		color: #999;
	}

	.bottom {
		margin-top: 13px;
		line-height: 12px;
		vertical-align:bottom;
	}

	.button {
		padding: 0;
		float: right;
	}

	.image {
		width: 100%;
		display: block;
	}

	.clearfix:before,
	.clearfix:after {
		display: table;
		content: "";
	}

	.clearfix:after {
		clear: both
	}
</style>
